import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgInstagramIcon from '../generated/svg/instagram-icon'
import SvgLogo from '../generated/svg/logo'

const Outer = styled.div`
  background-color: var(--background-color);
  color: white;
  padding: 0 var(--page-margin) 20rem;
  margin-bottom: -20rem;
  border-top: 1px solid #484848;
`

const Inner = styled.div`

`

const TopContainer = styled.div`
  display: flex;
  padding-top: 6.25rem;
  padding-bottom: 10rem;
  @media (max-width: 40rem){
    flex-wrap: wrap;
    padding-top: 4rem;
    padding-bottom: 4rem;
    position: relative;
  }
`

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #484848;
  padding-top: 2.5rem;
  padding-bottom: 3.75rem;
  flex-wrap: wrap;
`

const LogoContainer = styled.a`
  display: block;
  margin-right: auto;
  @media (max-width: 40rem){
    flex: 0 0 100%;
  }
  svg {
    width: 5.625rem;
    height: auto;
    display: block;
    @media (max-width: 40rem){
      width: 4rem;
    }
  }
`

const Navigator = styled.div`
  margin: -0.9375rem 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 40rem){
    flex: 0 0 50%;
    margin-left: 0;
    margin-top: 3rem;
  }
  & + & {
    margin-left: 7rem;
    @media (max-width: 40rem){
      margin-left: 0;
    }
  }
`

const Item1 = styled.a`
  display: block;
  font-size: 1.125rem;
  letter-spacing: 0.03em;
  font-family: forum, serif;
  padding: 0.9375rem 0;
  line-height: 1;
  @media (max-width: 40rem){
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`

const Item2 = styled.a`
  display: block;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  padding: 0.75rem 0;
`

const SnsContainer = styled.div`
  margin: -0.75rem;
  display: flex;
  margin-left: 7rem;
  @media (max-width: 40rem){
    position: absolute;
    right: 0; 
    flex-direction: column;
    top: 4rem;
  }
`

const SnsItem = styled.a`
  display: block;
  padding: 0.75rem;
  svg {
    display: block;
    height: 1.5rem;
    width: auto;
  }
`

const Copyright = styled.div`
  font-family: forum, serif;
  font-size: 0.8125rem;
  letter-spacing: 0.03em;
`

const PageTop = styled.a`
  display: block;
  font-family: forum, serif;
  font-size: 0.8125rem;
  letter-spacing: 0.03em;
`

const Note = styled.div`
  text-align: right;
  font-size: 0.8125rem;
  flex: 0 0 100%;
  margin-bottom: 1rem;
  margin-top: -1rem;
  opacity: 0.75;
`

type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  return <Outer>
    <Inner>
      <TopContainer>
        <LogoContainer href="/">
          <SvgLogo />
        </LogoContainer>
        <Navigator>
          <Item1 href="/shop/products">PRODUCT</Item1>
          <Item1 href="/shop/information_categories/topics">TOPICS</Item1>
          <Item1 href="/shop/faq">FAQ</Item1>
          <Item1 href="/shop/contact">CONTACT</Item1>
        </Navigator>
        <Navigator>
          <Item2 href="/shop/law_info">会社概要</Item2>
          <Item2 href="/shop/guide">ご利用ガイド</Item2>
          <Item2 href="/shop/customer_term">利用規約</Item2>
          <Item2 href="/shop/pages/rental_terms">カロニアレンタルサービス利用規約</Item2>
          <Item2 href="/shop/law_info">特定商取引法に基づく表記</Item2>
          <Item2 href="/shop/privacy">プライバシーポリシー</Item2>
        </Navigator>
        <SnsContainer>
          <SnsItem href="https://www.instagram.com/kalonear_official/" target="_blank">
            <SvgInstagramIcon />
          </SnsItem>
        </SnsContainer>
      </TopContainer>
      <BottomContainer>
        <Note>事業再構築</Note>
        <Copyright>© TRIANGLE&CO.</Copyright>
        <PageTop href="./" onClick={e => {
          e.preventDefault()
          window.scrollTo({top: 0, behavior: 'smooth'})
        }}>PAGE TOP</PageTop>
      </BottomContainer>
    </Inner>
  </Outer>
}